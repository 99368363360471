import { ResponseHttpCall } from '../interfaces/services/http-call.interface';
import { refreshTokenHook } from '../hooks/refresh-token.hook';
import { IUserLocalStorage } from '../interfaces';
import axios, { AxiosResponse } from 'axios';
import { EMainPaths } from '../enums';

class HttpCall {
   constructor() {
      this.initInterceptor();
   }

   private initInterceptor(): void {
      // add a request interceptor
      axios.interceptors.request.use(
         (config) => {
            // configure new headers
            const publicKey: string | undefined = import.meta.env.VITE_PUBLIC_KEY;
            if (publicKey) {
               config.headers['Public-key'] = publicKey;
            }
            const user: string | null = localStorage.getItem('user');
            if (user) {
               const userParse: IUserLocalStorage = JSON.parse(user);
               config.headers['Authorization'] = `Bearer ${userParse.token}`;
            }
            return config;
         },
         (error) => {
            // handle response errors here
            return Promise.reject(error);
         }
      );

      // add a response interceptor
      axios.interceptors.response.use(
         (response) => {
            // process the answer here
            return response;
         },
         (error) => {
            console.error('http-call error:', error);
            const currentRoute: string = window.location.pathname.split('/')[2];
            // handle response errors here
            if (error.response.status === 401 && currentRoute !== EMainPaths.Auth) {
               refreshTokenHook();
            }
            if (error.response.status === 500) {
               window.location.href = `/error/${error.response.status}`;
            }
            return Promise.reject(error);
         }
      );
   }

   public async get<T>(url: string, customHeaders?: Record<string, string>): Promise<ResponseHttpCall<T>> {
      try {
         const config = { headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...customHeaders } };
         const response: AxiosResponse<T> = await axios.get(url, config);
         return { data: response.data, status: response.status, failed: false };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, failed: true };
         }
         console.error('Unexpected error:', error.message);
         return { failed: true } as ResponseHttpCall<T>;
      }
   }

   public async post<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<ResponseHttpCall<T>> {
      try {
         const config = { headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...customHeaders } };
         const response: AxiosResponse<T> = await axios.post(url, body, config);
         return { data: response.data, status: response.status, failed: false };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, failed: true };
         }
         console.error('Unexpected error:', error.message);
         return { failed: true } as ResponseHttpCall<T>;
      }
   }

   public async put<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<ResponseHttpCall<T>> {
      try {
         const config = { headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...customHeaders } };
         const response: AxiosResponse<T> = await axios.put(url, body, config);
         return { data: response.data, status: response.status, failed: false };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, failed: true };
         }
         console.error('Unexpected error:', error.message);
         return { failed: true } as ResponseHttpCall<T>;
      }
   }

   public async patch<T>(url: string, body?: Record<any, any>, customHeaders?: Record<string, string>): Promise<ResponseHttpCall<T>> {
      try {
         const config = { headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...customHeaders } };
         const response: AxiosResponse<T> = await axios.patch(url, body, config);
         return { data: response.data, status: response.status, failed: false };
      } catch (error: any) {
         if (error.response) {
            return { data: error.response.data, status: error.response.status, failed: true };
         }
         console.error('Unexpected error:', error.message);
         return { failed: true } as ResponseHttpCall<T>;
      }
   }
}

export default new HttpCall();
