import { EMessage, refreshTokenHook } from '../../../shared/hooks/refresh-token.hook';
import { IConfiguration, IUserLocalStorage } from '../../../shared/interfaces';
import { getSession } from '../../../shared/hooks/user-session.hook';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';

export function AuthUrl() {
   // configuration
   const { token, slugCompany } = useParams();
   const userSession: IUserLocalStorage | null = getSession();
   const configuration: string | null = localStorage.getItem('configuration');
   const configurationParse: IConfiguration | null = configuration ? JSON.parse(configuration) : null;

   // effects
   useEffect(() => {
      if (token) {
         localStorage.setItem('user', JSON.stringify({ ...userSession, token: vilidateJwt(decodeBase64(token)) }));
         if (configurationParse) {
            localStorage.setItem('configuration', JSON.stringify({ ...configurationParse, slugCompany }));
         }
         if (slugCompany) {
            refreshTokenHook({ message: EMessage.Invalid, slugCompany });
         }
      }
   }, []);

   // mehotds
   const decodeBase64 = (token: string): string => {
      try {
         return atob(token);
      } catch (error) {
         console.error('error when decoding the string in base64:', error);
         return '';
      }
   };

   const vilidateJwt = (token: string): string => {
      try {
         jwt_decode(token);
         return token;
      } catch (error) {
         console.error('error validating the JWT:', error);
         return '';
      }
   };

   return <></>;
}
