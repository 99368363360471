export enum EPartTypes {
   Video = 'video'
}

export enum ECallPass {
   Expecting = 'expecting',
   Successful = 'successful',
   Failed = 'failed'
}

export enum EPartState {
   Blocked = 'block',
   Seeing = 'seening',
   Completed = 'completed'
}

export enum EGender {
   Male = 'man',
   Female = 'women',
   NotAnswer = 'not-answer'
}

export enum EStateLesson {
   Block = 'blocked',
   Check = 'check',
   Watch = 'watching',
   Play = 'play'
}

export enum ETypePart {
   Final = 'final',
   Video = 'video'
}

export enum EMainPaths {
   Login = 'login',
   Auth = 'auth'
}

export enum ELanguage {
   spanish = 'es',
   english = 'en'
}

export enum ELocalStorageItem {
   RememberData = 'remember-data'
}
